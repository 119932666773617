var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-guide__presentation" },
    [
      _c(
        "div",
        { staticClass: "quick-guide__top-img" },
        [_vm._t("top-image")],
        2
      ),
      _c("quick-guide-close"),
      _c("div", { staticClass: "quick-guide__presentation-holder" }, [
        _c("div", { staticClass: "quick-guide__presentation-top" }, [
          _c(
            "div",
            {
              staticClass:
                "quick-guide__presentation-top-wrapper flex items-center",
            },
            [
              _c(
                "div",
                { staticClass: "quick-guide__presentation-info flex flex-col" },
                [
                  _c(
                    "div",
                    { staticClass: "quick-guide__presentation-title" },
                    [_vm._t("title")],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "quick-guide__presentation-description" },
                    [_vm._t("description")],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "quick-guide__right-img" },
                [_vm._t("right-img")],
                2
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "quick-guide__presentation-bottom" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "quick-guide__bottom-center-img" },
            [_vm._t("bottom-center-image")],
            2
          ),
          _c(
            "div",
            { staticClass: "quick-guide__presentation-actions" },
            [_vm._t("actions")],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quick-guide__presentation-bottom-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/quick-guide/logo.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }