var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "inAppMessagingCarousel",
      staticClass: "swiper quick-guide-carousel",
      attrs: { options: _vm.swiperOptions },
    },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        [
          _vm._l(_vm.quickGuideItemsLocalized, function (item, index) {
            return _c("quick-guide-carousel-slide", {
              key: item.id,
              staticClass: "swiper-slide",
              attrs: { item: item, index: index },
            })
          }),
          _c(
            "div",
            { key: "quick-guide-end", staticClass: "swiper-slide" },
            [_c("quick-guide-end")],
            1
          ),
        ],
        2
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quick-guide-carousel__nav" }, [
      _c("div", { staticClass: "swiper-button-prev" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("west")]),
      ]),
      _c("div", { staticClass: "swiper-pagination" }),
      _c("div", { staticClass: "swiper-button-next" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("east")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }