<template>
  <div>
    <vs-prompt
      :title="$t('vue.deleteDialog')"
      @cancel="selectedDialog = null"
      @accept="onDeleteDialog(selectedDialog.id)"
      @close="selectedDialog = null"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt" v-if="selectedDialog">
        <p>
          <strong>{{ selectedDialog.name }}</strong>
        </p>
        {{ $t('vue.deleteDialogMessage') }}
      </div>
    </vs-prompt>
    <div class="vx-row embed-list-button-add">
      <div class="vx-col w-full mb-8">
        <vs-button icon-pack="feather" icon="icon-plus" type="filled" @click="onAddDialog">{{ $t('vue.addWebConnect') }}</vs-button>
      </div>
    </div>
    <div class="quick-guide-embedlist-holder">
      <quick-guide-start
        v-if="
          dialogs.length === 0 &&
          activeUserInfo &&
          !activeUserInfo.isQuickGuideStarted &&
          !activeUserInfo.isQuickGuideFinished &&
          !activeUserInfo.isQuickGuideInProgress
        "
      />
      <quick-guide-carousel
        v-if="activeUserInfo && activeUserInfo.isQuickGuideStarted && activeUserInfo.isQuickGuideInProgress && !activeUserInfo.isQuickGuideFinished"
      />
    </div>
    <div v-if="dialogs.length > 0" class="vx-row">
      <div v-for="dialog in widgets" :key="dialog.id" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base dialog-wrapper">
        <vx-card class="overflow-hidden dialog-container" style="cursor: pointer">
          <div class="dialog__heading">
            <div class="w-full flex justify-between">
              <div>
                <h2 class="pr-3 font-bold dialog__title">{{ dialog.name }}</h2>
              </div>
              <div class="flex items-center justify-end">
                <div>
                  <vs-switch color="#275D73" v-model="dialog.isEnabled" @input="updateDialogVisibility(dialog)" />
                </div>
              </div>
              <!-- Shopify Inteegration One Clic -->
              <!-- This is handled via app embed block -->

              <!-- <div v-if="company.isShopifyAccount && company.shopifyStoreId" class="mt-2">
                <div v-if="shop && shop.dialogId !== dialog.id" @click="installScriptInShopify(dialog)">
                  <shopify-one-step-integration />
                </div>
                <div v-if="shop && shop.dialogId === dialog.id && dialog.scriptTagId" class="flex items-center justify-center">
                  <shopify-installed-integration class="mr-2" />
                  <div @click="uninstallScriptFromShopify(dialog)">
                    <shopify-uninstall-integration />
                  </div>
                </div>
              </div> -->
            </div>

            <!-- -->
          </div>

          <div class="mt-auto text-center dialog__actions">
            <feather-icon
              icon="EditIcon"
              class="inline-flex rounded-full dialog__icon"
              :style="{ background: `rgba(var(--vs-primary),.15)`, color: '#275D73' }"
              @click="onEditDialog(dialog.id)"
            />

            <feather-icon
              icon="SettingsIcon"
              class="inline-flex rounded-full dialog__icon"
              :style="{
                color: 'rgb(28, 132, 57)',
                background: `rgb(210, 230, 215)`
              }"
              @click="onEditSettings(dialog.id)"
            />

            <feather-icon
              icon="TrashIcon"
              class="inline-flex rounded-full text-danger dialog__icon"
              :style="{ background: `rgba(var(--vs-danger),.15)` }"
              @click=";(selectedDialog = { id: dialog.id, name: dialog.name }), (deletePrompt = true)"
            />

            <feather-icon
              icon="CopyIcon"
              class="inline-flex rounded-full text-warning dialog__icon"
              :style="{ background: `rgba(var(--vs-warning),.15)` }"
              @click="onCopyWidget(dialog.id)"
            />

            <div v-if="!(!HAS_ABTESTING_ACCESS && isMobileApp)" align="center" class="dialog-widgetbox-footer">
              <VegaActivatedIcon v-if="dialog.isVegaWidget && dialog.isVegaWidgetEnabled && dialog.vega" class="dialog-widgetbox-footer--icon" />
              <vs-button
                :icon="!HAS_ABTESTING_ACCESS ? 'lock' : ''"
                type="filled"
                class="rad-25 dialog__button dialog__button_filled"
                color="warning"
                :class="{ dialog__button_disabled: !HAS_ABTESTING_ACCESS }"
                :disabled="dialog.type === 'custom' || !HAS_ABTESTING_ACCESS"
                @click="abTesting(dialog.id)"
              >
                {{ $t('routes.abtesting') }}
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Device } from '@capacitor/device'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import QuickGuideStart from '@/components/quick-guide/QuickGuideStart.vue'
import QuickGuideCarousel from '@/components/quick-guide/QuickGuideCarousel.vue'
// import ShopifyOneStepIntegration from '@/components/icons/ShopifyOneStepIntegration.vue'
// import ShopifyInstalledIntegration from '@/components/icons/ShopifyInstalledIntegration.vue'
// import ShopifyUninstallIntegration from '@/components/icons/ShopifyUninstallIntegration.vue'

import VegaActivatedIcon from '@/components/icons/vega/VegaActivatedIcon.vue'

export default {
  components: {
    QuickGuideStart,
    QuickGuideCarousel,
    VegaActivatedIcon
    // ShopifyOneStepIntegration
    //ShopifyInstalledIntegration,
    //ShopifyUninstallIntegration
  },
  data() {
    return {
      isMobileApp: false,
      isQuickGuideStarted: false,
      isQuickGuideInProgress: false,
      isQuickGuideFinished: false,
      deletePrompt: false,
      selectedDialog: null,
      infoImg: require('@/assets/images/elements/info.svg'),
      unsubscribeShop: null,
      shop: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      dialogs: 'dialogs'
    }),
    widgets() {
      const sortedWidgets = [...this.dialogs].sort((a, b) => {
        return b.created.seconds - a.created.seconds
      })
      return sortedWidgets
    },
    HAS_ABTESTING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('ab-testing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    defaultDialogName() {
      return this.$i18n.t('vue.newDialog')
    },
    SHOW_GOTO_SHOPIFYSTORE() {
      if (this.company.isShopifyAccount && this.company.shopifyStoreId && this.shop && this.shop.shop && this.shop.shop.domain) {
        return true
      }
      return false
    }
  },
  methods: {
    useRouteParams() {
      if (this.$route.params.action) {
        switch (this.$route.params.action) {
          case 'add-web-connect': {
            setTimeout(() => {
              this.onAddDialog()
            }, 200)

            break
          }
        }
      }
    },

    getNameForCopiedWidget(name) {
      let count = 1
      let newName = `Copy of ${name}`
      let nameExists = this.dialogs.some((w) => w.name === newName)
      while (nameExists) {
        count++
        newName = `Copy of ${name} ${count}`
        nameExists = this.dialogs.some((w) => w.name === newName)
      }
      return newName
    },
    async onCopyWidget(widgetId) {
      await this.$vs.loading()
      const _dialogRef = await this.$db.collection('dialogs').doc(widgetId).get()
      const _dialog = _dialogRef.data()
      const name = this.getNameForCopiedWidget(_dialog.name)
      _dialog.created = dayjs().utc().toDate()
      _dialog.name = name
      _dialog.createdBy = this.activeUserInfo.uid
      delete _dialog.dialogUpdated
      delete _dialog.testId

      await this.$db.collection('dialogs').add(_dialog)
      await this.$vs.loading.close()
    },
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async uninstallScriptFromShopify(dialog) {
      await this.$vs.loading()
      const uninstallScriptFromShopify = this.$functions.httpsCallable('uninstallScriptFromShopify')
      const result = await uninstallScriptFromShopify({
        dialogId: dialog.id,
        shopifyStoreId: this.company.shopifyStoreId.toString(),
        companyId: this.activeUserInfo.company
      })
      if (result && result.data && result.data.success) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('shopify.uninstalledScriptSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } else {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('shopify.couldNotUninstallScript'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      await this.$vs.loading.close()
    },
    async installScriptInShopify(dialog) {
      await this.$vs.loading()
      const installScriptInShopify = this.$functions.httpsCallable('installScriptInShopify')
      const result = await installScriptInShopify({
        dialogId: dialog.id,
        shopifyStoreId: this.company.shopifyStoreId.toString(),
        companyId: this.activeUserInfo.company
      })
      if (result && result.data && result.data.success) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('shopify.installedScriptSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } else {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('shopify.couldNotInstallScript'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      await this.$vs.loading.close()
    },
    getNewDialogName() {
      if (!this.dialogs || this.dialogs.length === 0) {
        return this.defaultDialogName
      }
      if (!this.dialogs.find((x) => x.name === this.defaultDialogName)) {
        return this.defaultDialogName
      }
      for (let i = 1; ; i++) {
        const name = `${this.defaultDialogName} ${i}`
        if (!this.dialogs.find((x) => x.name === name)) {
          return name
        }
      }
    },
    async updateDialogVisibility(dialog) {
      await this.$vs.loading()

      const data = {
        visibility: dialog.isEnabled ? 'always' : 'permission'
      }

      await this.$db.collection('dialogs').doc(dialog.id).set(data, { merge: true })

      await this.$vs.loading.close()
    },
    abTesting(dialogId) {
      if (!this.HAS_ABTESTING_ACCESS) {
        return
      }
      this.$router.push(`/ab-testing/${dialogId}`)
    },
    async updateDialogB2B(dialog) {
      const dialogRef = await this.$db.collection('dialogs').doc(dialog.id)
      await dialogRef.set({ b2bOnly: dialog.b2bOnly }, { merge: true })
    },

    getDefaultDialogValues() {
      const dialog = {
        name: this.$i18n.t('vue.newDialog', 'en'),
        title: this.$i18n.t('vue.dialogTitleDefault', 'en'),
        text: this.$i18n.t('vue.popupTextDefault', 'en'),
        cancelButtonText: this.$i18n.t('vue.cancelButtonTextDefault', 'en'),
        confirmButtonText: this.$i18n.t('vue.confirmButtonTextDefault', 'en'),
        confirmButtonColor: '#238000',
        switchVisibility: true,
        sloganTitle: "Let's Connect!",
        visibleTimeout: 4,
        contactFormTimeout: 60,
        created: new Date(),
        showVideo: false,
        type: 'button-card',
        visibility: 'always',
        locale: this.$i18n.locale,
        createdBy: this.activeUserInfo.uid,
        dialogUpdated: new Date(),
        communicationOptions: {
          chat: true,
          audio: true,
          video: true
        },
        widgetUse: 'sales'
      }

      /* New Dialog Fields */
      dialog.isMoreInformationEnabled = false
      dialog.moreInformationButtonText = this.$i18n.t('vue.moreInformation', 'en')
      dialog.sloganTitle = "Let's Connect!"
      dialog.isAlwaysShowButtonEnabled = false
      dialog.isMinimizeSmallButtonEnabled = false
      dialog.isSmallButtonPreviewVisible = false
      dialog.showDefaultMedia = true
      dialog.useOneMediaForAllSteps = false
      dialog.useNewButtonPlusTextStyle = false
      dialog.buttonPlusTextNewMediaIconColor = '#3034F7'
      dialog.allMedia = null
      dialog.buttonMedia = null
      dialog.smallButtonMedia = null
      dialog.welcomeScreenMedia = null
      dialog.buttonPlusTextNewMedia = null
      dialog.smallButtonImage = null

      dialog.textStep2 = this.$i18n.t('vue.textStep2DefaultText', 'en')
      dialog.step2ButtonColor = '#3B86F7'
      dialog.step2TextColor = '#ffffff'
      dialog.step2IconColor = '#ffffff'
      dialog.waitingRoomMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')

      dialog.waitingRoomContentChatMessage = {
        backgroundColor: '#C4C4C4',
        fontColor: '#12598d'
      }

      dialog.sessionEndScreen = {
        titleClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en'),
        copyClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF'
      }

      dialog.isShowFeedbackForm = false
      dialog.isSendFeedbackViaMessage = false
      dialog.feedbackFormQuestionFirst = ''
      dialog.feedbackFormQuestionSecond = ''
      dialog.feedbackFormQuestionThird = ''

      dialog.isAutomatedChatResponseEnabled = false
      dialog.automatedChatResponse = this.$i18n.t('webConnect.automatedChatResponse', 'en')

      dialog.contactForm = {
        message: this.$i18n.t('vue.contactFormText', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF',
        isBookingEnabled: false,
        selectedAppointment: null,
        fields: {
          name: {
            enabled: true,
            required: true
          },
          email: {
            enabled: true,
            required: true
          },
          phone: {
            enabled: true,
            required: true
          },
          message: {
            enabled: true,
            required: true
          },
          date: {
            enabled: false,
            required: false
          }
        },
        sendMessageTitle: this.$i18n.t('vue.sendMessageDefaultTitle', 'en'),
        sendMessageCopy: this.$i18n.t('vue.sendMessageDefaultCopy', 'en'),
        bookingSlotTitle: this.$i18n.t('vue.bookingSlotDefaultTitle', 'en'),
        bookingSlotCopy: this.$i18n.t('vue.bookingSlotDefaultCopy', 'en'),
        isPrivacyInformationEnabled: false,
        isDefaultContactFormInsideChat: false,
        privacyInformationHtml: this.$i18n.t('vue.privacyInformationText', 'en')
      }
      /* New Dialog Fields */

      dialog.companyDisplayName = this.company && this.company.companyDisplayName ? this.company.companyDisplayName : null

      return dialog
    },
    async onAddDialog() {
      const vm = this
      await vm.$vs.loading()

      const dialog = this.getDefaultDialogValues()

      dialog.company = vm.activeUserInfo.company
      const dialogRef = await vm.$db.collection('dialogs').add(dialog)
      if (dialogRef && this.dialogs.length === 0) {
        this.isQuickGuideFinished = true
        this.isQuickGuideInProgress = false
        await this.$db.collection('users').doc(this.activeUserInfo.uid).set(
          {
            isQuickGuideFinished: this.isQuickGuideFinished,
            isQuickGuideInProgress: this.isQuickGuideInProgress
          },
          { merge: true }
        )
      }
      this.$router.push(`/web-connect/${dialogRef.id}`)
      await vm.$vs.loading.close()
    },
    onEditDialog(id) {
      this.$router.push(`/web-connect/${id}`)
    },
    onEditSettings(id) {
      this.$router.push(`/web-connect/settings/${id}`)
    },
    async onDeleteDialog(id) {
      await this.$vs.loading()
      await this.$db.collection('dialogs').doc(id).delete()
      await this.$vs.loading.close()
    }
  },
  async beforeMount() {
    if (this.activeUserInfo && this.activeUserInfo.uid && this.$route && this.$route.name === 'dialog-after-successfull-registration') {
      if (!this.activeUserInfo.GTM_REGISTRATION_SUCCESSFUL) {
        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ GTM_REGISTRATION_SUCCESSFUL: true }, { merge: true })
      } else {
        this.$router.push({ name: 'dialog' })
      }
    }

    if (this.company && this.company.isShopifyAccount && this.company.shopifyStoreId) {
      try {
        const query = this.$db.collection('shopify-accounts').doc(this.company.shopifyStoreId.toString())
        this.unsubscribeShop = query.onSnapshot(async (ref) => {
          this.shop = ref.data()
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    }
  },

  created() {
    this.checkIsMobileApp()
  },

  mounted() {
    this.useRouteParams()

    this.$serverBus.$on('user-updated', () => {
      if (
        this.activeUserInfo.isQuickGuideInProgress ||
        typeof this.activeUserInfo.isQuickGuideStarted === 'undefined' ||
        typeof this.activeUserInfo.isQuickGuideInProgress === 'undefined' ||
        typeof this.activeUserInfo.isQuickGuideFinished === 'undefined'
      ) {
        document.getElementById('pa-overlay').style.display = 'block'
      } else {
        document.getElementById('pa-overlay').style.display = 'none'
      }
    })
    this.$serverBus.$on('quick-guide-start', async () => {
      this.isQuickGuideFinished = false
      this.isQuickGuideStarted = true
      this.isQuickGuideInProgress = true
      await this.$db.collection('users').doc(this.activeUserInfo.uid).set(
        {
          isQuickGuideStarted: this.isQuickGuideStarted,
          isQuickGuideInProgress: this.isQuickGuideInProgress,
          isQuickGuideFinished: this.isQuickGuideFinished
        },
        { merge: true }
      )
      document.getElementById('pa-overlay').style.display = 'block'
      this.$router.push({ name: 'dialog' }).catch(() => {})
    })
    this.$serverBus.$on('quick-guide-close', async () => {
      this.isQuickGuideFinished = true
      this.isQuickGuideInProgress = false
      await this.$db
        .collection('users')
        .doc(this.activeUserInfo.uid)
        .set({ isQuickGuideFinished: this.isQuickGuideFinished, isQuickGuideInProgress: this.isQuickGuideInProgress }, { merge: true })
      document.getElementById('pa-overlay').style.display = 'none'
    })
    this.$serverBus.$on('create-first-dialog', async () => {
      this.isQuickGuideStarted = true
      this.isQuickGuideFinished = true
      this.isQuickGuideInProgress = false
      await this.$db.collection('users').doc(this.activeUserInfo.uid).set(
        {
          isQuickGuideStarted: this.isQuickGuideStarted,
          isQuickGuideFinished: this.isQuickGuideFinished,
          isQuickGuideInProgress: this.isQuickGuideInProgress
        },
        { merge: true }
      )
      document.getElementById('pa-overlay').style.display = 'none'
      const dialog = this.getDefaultDialogValues()
      dialog.company = this.activeUserInfo.company
      const dialogRef = await this.$db.collection('dialogs').add(dialog)
      if (dialogRef) {
        this.$router.push(`/web-connect/${dialogRef.id}`)
      }
    })

    if (this.$route && this.$route.params && this.$route.params.from === 'appsumo-activate') {
      this.$serverBus.$emit('quick-guide-restart')
    }
  },
  beforeDestroy() {
    if (this.unsubscribeShop) {
      this.unsubscribeShop()
    }
    this.$serverBus.$off('quick-guide-start')
    this.$serverBus.$off('quick-guide-close')
    this.$serverBus.$off('create-first-dialog')
    this.$serverBus.$off('user-updated')
  }
}
</script>

<style lang="scss">
.rad-25 {
  border-radius: 25px;
}

.quick-guide-embedlist-holder {
  @media (min-width: 1921px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.embed-list-button-add {
  @media (max-width: 767px) {
    display: none;
  }
}

.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;

  &-widgetbox-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &--icon {
      margin-right: 20px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
  }

  &__actions {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__title {
    overflow: hidden;
    font-size: 25px;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &-wrapper {
    @media (max-width: 767px) {
      margin-bottom: 15px !important;
    }
  }

  &__icon {
    margin: 36px 10px 20px;
    padding: 10px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__button {
    width: 160px;

    &_filled {
      color: rgb(240, 169, 65);
      background: rgba(240, 169, 65, 0.15) !important;
    }

    &_disabled {
      color: #ccc;
      background: rgba(204, 204, 204, 0.15) !important;
    }
  }

  &-container {
    position: relative;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-4px);
    }

    .vx-card__body {
      display: flex;
      flex-direction: column;
      padding: 14px 20px 26px;

      @media (min-width: 768px) {
        min-height: 212px;
      }

      @media (max-width: 767px) {
        padding: 22px 16px !important;
      }
    }
  }
}
</style>
