<template>
  <div class="quick-guide__presentation">
    <div class="quick-guide__top-img">
      <slot name="top-image"></slot>
    </div>
    <quick-guide-close />
    <div class="quick-guide__presentation-holder">
      <div class="quick-guide__presentation-top">
        <div class="quick-guide__presentation-top-wrapper flex items-center">
          <div class="quick-guide__presentation-info flex flex-col">
            <div class="quick-guide__presentation-title">
              <slot name="title"></slot>
            </div>
            <div class="quick-guide__presentation-description">
              <slot name="description"></slot>
            </div>
          </div>
          <div class="quick-guide__right-img">
            <slot name="right-img"></slot>
          </div>
        </div>
      </div>
      <div class="quick-guide__presentation-bottom">
        <div class="quick-guide__presentation-bottom-img">
          <img src="@/assets/images/quick-guide/logo.svg" />
        </div>
        <div class="quick-guide__bottom-center-img">
          <slot name="bottom-center-image"></slot>
        </div>
        <div class="quick-guide__presentation-actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickGuideClose from '@/components/quick-guide/QuickGuideClose'

export default {
  name: 'QuickGuidePresentation',
  components: {
    QuickGuideClose
  }
}
</script>

<style lang="scss" scoped>
.quick-guide {
  overflow: hidden;
  &__right-img {
    @media (max-width: 767px) {
      max-width: 200px;
      img {
        max-width: 100%;
      }
    }
  }
  &__bottom-center-img {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      order: 2;
    }
  }
  &__top-img {
    display: none;
    position: absolute;
    right: -20px;
    top: 0;
    overflow: hidden;
    img {
      width: 110%;
    }
    @media (max-width: 991px) {
      display: flex;
    }
  }
  &__presentation {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 478px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-sizing: border-box;
    padding-top: 50px;
    max-width: 1120px;
    &-holder {
      width: 100%;
    }
    &._start {
      background: url('../../assets/images/quick-guide/girlande-desk.svg') right -20px top no-repeat,
        url('../../assets/images/quick-guide/ballons-desk.svg') top 64px left -20px no-repeat, #f0a941;
      background-position: center;
      z-index: 54000;
      @media (min-width: 1921px) {
        margin: 0 auto;
      }
      @media (max-width: 1440px) {
        background: url('../../assets/images/quick-guide/girlande-desk.svg') right -20px top no-repeat,
          url('../../assets/images/quick-guide/ballons-desk.svg') top 64px left/90px 158px no-repeat, #f0a941;
        .quick-guide__presentation-info {
          padding-left: 30px;
        }
      }
      @media (max-width: 991px) {
        background: #f0a941;
        .quick-guide__presentation-info {
          padding-left: 0;
        }
        .quick-guide__presentation-top {
          padding-bottom: 30px;
        }
      }
      @media (max-width: 767px) {
        padding-top: 0;
        .quick-guide__bottom-center-img {
          img {
            width: 72px;
            height: 150px;
            object-fit: cover;
          }
        }
        .auth-button {
          font-size: 14px;
        }
      }
      .quick-guide__presentation-info {
        max-width: 800px;
      }
    }
    &._end {
      background: url('../../assets/images/quick-guide/clouds/cloud-1.svg') top 156px left 30px no-repeat,
        url('../../assets/images/quick-guide/clouds/cloud-2.svg') top 244px left 113px no-repeat,
        url('../../assets/images/quick-guide/clouds/cloud-3.svg') top 241px right 28px no-repeat,
        url('../../assets/images/quick-guide/clouds/cloud-4.svg') top 76px left 504px no-repeat,
        url('../../assets/images/quick-guide/clouds/cloud-5.svg') top 355px left 672px no-repeat,
        url('../../assets/images/quick-guide/clouds/cloud-6.svg') top 119px left 776px no-repeat, #f0a941;
      background-position: center;
      background-repeat: no-repeat;
      .quick-guide__right-img {
        max-width: 240px;
        width: 100%;
        margin-top: -60px;
        margin-right: -80px;
        img {
          max-width: 100%;
        }
      }
      @media (min-width: 992px) {
        height: 478px;
        .quick-guide__presentation-top {
          padding-bottom: 0;
        }
      }
      @media (max-width: 991px) {
        background: url('../../assets/images/quick-guide/end.png'), #f0a941;
        background-position: center;
        background-repeat: no-repeat;
        .quick-guide__right-img {
          max-width: unset;
          width: unset;
          margin-top: unset;
          margin-right: unset;
          img {
            max-width: unset;
          }
        }
        .quick-guide__presentation-top {
          padding-bottom: 60px;
        }
        .quick-guide__presentation-bottom-img {
          display: none;
        }
      }
      @media (max-width: 767px) {
        align-items: flex-start;
        padding-top: 0;
        background-size: 85%;
        background-position: center bottom 20%;
        .quick-guide__presentation-top {
          padding-bottom: 0;
        }
        .quick-guide__right-img {
          height: 158px;
          img {
            height: 100%;
          }
        }

        .quick-guide__presentation-top {
          margin-bottom: 0;
        }
      }
      @media (max-width: 475px) {
        background-position: center bottom 60px;
      }
    }
    &-title {
      font-size: 30px;
      font-weight: bold;

      letter-spacing: 0.15em;
      color: #fff;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        font-size: 20px;
        margin-bottom: 33px;
      }
    }
    &-description {
      width: 100%;
      font-size: 28px;
      color: #fff;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    &-info {
      width: 100%;
      max-width: 500px;
    }
    &-top {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
      padding-top: 30px;
      @media (max-width: 991px) {
        padding: 0 30px;
      }
      @media (max-width: 767px) {
        padding-top: 114px;
        margin-bottom: 20px;
      }
      @media (max-width: 364px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      &-wrapper {
        @media (max-width: 767px) {
          flex-direction: column;
        }
      }
    }
    &-bottom {
      padding: 0 30px 30px 30px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;
      @media (max-width: 991px) {
        align-items: flex-end;
        &-img {
          order: 2;
        }
      }
      @media (max-width: 991px) {
        &-img {
          min-width: 20px;
          width: 20px;
          height: 20px;
          img {
            max-width: 100%;
          }
        }
      }
    }
    &-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 991px) {
        order: 2;
      }
    }
  }
}
</style>
