<template>
  <quick-guide-presentation class="_end">
    <template #title>{{ $t('quickGuide.endTitle') }}</template>
    <template #description>
      <p class="mb-6">{{ $t('quickGuide.endSubtitle') }}</p>
      <p>{{ $t('quickGuide.endSubtitle2') }}</p>
      <p class="mt-6" style="color: #12598d; font-weight: 500" v-if="isMobile">{{ $t('quickGuide.endSubtitle3') }}</p>
      <auth-button class="mt-6" @action-click="onActionClick" v-if="!isMobile">
        {{ $t('quickGuide.createYourFirstDialog') }}
        <template #right-icon>
          <svg class="ml-2" width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6.47363L20.4319 6.47363" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 1.37096L21 6.47406L15 11.5771" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </template>
      </auth-button>
    </template>
    <template #right-img>
      <img :src="require('@/assets/images/quick-guide/rocket.svg')" alt="rocket" />
    </template>
  </quick-guide-presentation>
</template>

<script>
import AuthButton from '@/components/buttons/auth/AuthButton.vue'
import QuickGuidePresentation from '@/components/quick-guide/QuickGuidePresentation.vue'
export default {
  name: 'QuickGuideEnd',
  components: {
    QuickGuidePresentation,
    AuthButton
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 991
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    onActionClick() {
      this.$serverBus.$emit('create-first-dialog')
    }
  }
}
</script>
