<template>
  <component
    :is="tag"
    class="auth-button"
    :class="{
      _orange: btnStyle === 'orange',
      _grey: btnStyle === 'grey',
      _white: btnStyle === 'white'
    }"
    @click.prevent="$emit('action-click')"
  >
    <div class="auth-button__left-icon" :class="{ 'auth-button__left-icon_absolute': isIconAbsolute }">
      <slot name="left-icon"></slot>
    </div>
    <div class="auth-button__text">
      <slot></slot>
    </div>
    <slot name="right-icon"></slot>
  </component>
</template>

<script>
export default {
  name: 'AuthButton',
  props: {
    tag: {
      type: String,
      default: 'button',
      required: false
    },
    btnStyle: {
      type: String,
      required: false
    },
    isIconAbsolute: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #12598d;
  box-shadow: inset -4px -4px 10px 0 rgba(0, 52, 80, 0.8), inset 4px 4px 10px 0 rgba(0, 52, 80, 0.23);
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  opacity: 1;

  transition: 0.3s ease-in-out;
  min-height: 47px;
  line-height: 26px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  &._middle {
    .auth-button__text {
      margin-left: unset;
      margin-right: unset;
    }
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
  &__text {
    display: flex;
    margin-right: auto;
    margin-left: auto;

    font-weight: 400;
    font-family: 'Lato';
  }
  &__left-icon {
    position: relative;
    display: flex;
    align-self: center;
    justify-content: center;
    img,
    i,
    svg {
      margin-right: 30px;
    }
    &_absolute {
      position: absolute;
      left: 30px;
    }
  }
  svg {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: 0.3s ease-in-out;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:not(:disabled):hover {
    border-color: #12598d;
    background-color: transparent;
    box-shadow: inset 4px 4px 10px 0 rgba(18, 89, 141, 0.23);
    color: #12598d;
    transition: 0.3s ease-in-out;
    .auth-button__text {
      color: #12598d;
    }
    svg {
      transition: 0.3s ease-in-out;
      transform: translate3d(10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      path {
        stroke: #12598d;
      }
    }
  }
  &._orange {
    color: #fff;
    border-color: #f0a941;
    background-color: #f0a941;
    box-shadow: inset -5px -5px 10px rgba(250, 251, 255, 0.5), inset 5px 5px 10px rgba(22, 27, 29, 0.16);
    transition: 0.3s ease-in-out;
    .auth-button__text {
      color: #fff;
    }
    svg {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      transition: 0.3s ease-in-out;
    }
    &:not(:disabled):hover {
      border-color: #f0a941;
      color: #fff;
      background-color: rgba(#f0a941, 0.8);
      color: #f0a941;
      transition: 0.3s ease-in-out;
      .auth-button__text {
        color: #fff;
      }
      svg {
        transition: 0.3s ease-in-out;
        transform: translate3d(10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        path {
          stroke: #fff;
        }
      }
    }
  }
  &._grey {
    background-color: #e6ecf1;
    box-shadow: inset -5px -5px 10px rgba(250, 251, 255, 0.5), inset 5px 5px 10px rgba(22, 27, 29, 0.16);
    color: #12598d;
    transition: 0.3s ease-in-out;
    svg {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      transition: 0.3s ease-in-out;
      path {
        stroke: #12598d;
      }
    }
    &:not(:disabled):hover {
      border-color: #e6ecf1;
      background-color: #d0dee8;
      color: #12598d;
      transition: 0.3s ease-in-out;
      .auth-button__text {
        color: #12598d;
      }
      svg {
        transition: 0.3s ease-in-out;
        transform: translate3d(10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        path {
          stroke: #12598d;
        }
      }
    }
  }
  &._white {
    background-color: #fff;
    box-shadow: inset -5px -5px 10px rgba(250, 251, 255, 0.5), inset 5px 5px 10px rgba(22, 27, 29, 0.16);
    color: #000;
    border: none;
    transition: 0.3s ease-in-out;
    svg {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      transition: 0.3s ease-in-out;
      path {
        stroke: #12598d;
      }
    }
    &:not(:disabled):hover {
      background-color: #fff;
      background-color: transparent;
      color: #000;
      transition: 0.3s ease-in-out;
      .auth-button__text {
        color: #000;
      }
      svg {
        transition: 0.3s ease-in-out;
        transform: translate3d(10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        path {
          stroke: #12598d;
        }
      }
    }
  }
}
</style>
