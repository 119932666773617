<template>
  <div class="quick-guide__close" @click.prevent="$serverBus.$emit('quick-guide-close')">
    <close-icon :width="20" :height="20"></close-icon>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue'
export default {
  name: 'QuickGuideClose',
  components: {
    CloseIcon
  }
}
</script>

<style scoped lang="scss">
.quick-guide {
  &__close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30px;
    right: 30px;
    cursor: pointer;
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-color: rgba(var(--vs-secondary), 1);
    border-radius: 4px;

    @media (max-width: 1024px) {
      top: 20px;
      right: 20px;
      background-color: rgb(48, 52, 247);
      border-radius: 6px;
    }
  }
}
</style>
