var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "44",
        height: "36",
        viewBox: "0 0 44 36",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M33.299 0.621094H24.7276L17.655 19.8717L16.9055 22.2136L16.203 19.8717L9.13038 0.621094H0.55896L13.0648 33.4079H20.7931L22.6471 28.5473C21.1238 26.7314 20.2066 24.39 20.2066 21.8344C20.2066 16.5732 24.094 12.2199 29.1532 11.4902L33.299 0.621094ZM23.5761 26.1118L28.2242 13.9258C24.8434 14.9651 22.3858 18.1127 22.3858 21.8344C22.3858 23.3998 22.8206 24.8637 23.5761 26.1118Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.2966 31.2984C35.4672 31.2984 39.6587 27.1068 39.6587 21.9363C39.6587 16.7658 35.4672 12.5742 30.2966 12.5742",
          stroke: _vm.color,
          "stroke-width": "2.17919",
          "stroke-linecap": "square",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.0001 33.6414L36.9094 28.5508",
          stroke: _vm.color,
          "stroke-width": "2.17919",
          "stroke-linecap": "square",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: { cx: "26.5", cy: "29.5", r: "6.5", fill: "#1C8439" },
      }),
      _c("path", {
        attrs: {
          d: "M29.3052 27.7852L25.7302 31.6852L24.1052 29.9124",
          stroke: "white",
          "stroke-width": "1.3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }