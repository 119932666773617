<template>
  <div class="swiper quick-guide-carousel" ref="inAppMessagingCarousel" :options="swiperOptions">
    <div class="swiper-wrapper">
      <quick-guide-carousel-slide class="swiper-slide" v-for="(item, index) in quickGuideItemsLocalized" :key="item.id" :item="item" :index="index" />
      <div class="swiper-slide" :key="'quick-guide-end'">
        <quick-guide-end />
      </div>
    </div>
    <div class="quick-guide-carousel__nav">
      <div class="swiper-button-prev">
        <i class="material-icons">west</i>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next">
        <i class="material-icons">east</i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swiper, { Autoplay, Pagination, Navigation, EffectFade, EffectFlip, EffectCoverflow } from 'swiper'
import 'swiper/swiper-bundle.min.css'
Swiper.use([Autoplay, Pagination, Navigation, EffectFade, EffectFlip, EffectCoverflow])

import QuickGuideCarouselSlide from '@/components/quick-guide/QuickGuideCarouselSlide'
import QuickGuideEnd from '@/components/quick-guide/QuickGuideEnd.vue'

export default {
  components: {
    QuickGuideCarouselSlide,
    QuickGuideEnd
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        slidesPerView: 1,
        effect: 'slide',
        spaceBetween: 30,
        direction: 'horizontal',
        autoHeight: false,
        breakpoints: {
          // when window width is >= 768px
          768: {
            autoHeight: true
          }
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className}">${index + 1}</span>`
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      quickGuideItems: [
        {
          id: 0,
          title: 'routes.home',
          description: 'quickGuide.slide1',
          image: require('@/assets/images/quick-guide/pages/dashboard.png')
        },
        {
          id: 1,
          title: 'routes.visitors',
          description: 'quickGuide.slide2',
          image: require('@/assets/images/quick-guide/pages/visitors.png')
        },
        {
          id: 2,
          title: 'routes.incomingRequests',
          description: 'quickGuide.slide3',
          image: require('@/assets/images/quick-guide/pages/incoming-requests.png')
        },
        {
          id: 3,
          title: 'routes.leadoverview',
          description: 'quickGuide.slide4',
          image: require('@/assets/images/quick-guide/pages/lead-overview.png')
        },
        {
          id: 4,
          title: 'routes.onlineMeetings',
          description: 'quickGuide.slide5',
          image: require('@/assets/images/quick-guide/pages/online-meeting.png')
        },
        {
          id: 5,
          title: 'routes.dialog',
          description: 'quickGuide.slide6',
          image: require('@/assets/images/quick-guide/pages/web-connect.png')
        },
        {
          id: 6,
          title: 'routes.campaigns',
          description: 'quickGuide.slide7',
          image: require('@/assets/images/quick-guide/pages/qr-connect.png')
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs'
    }),
    quickGuideItemsLocalized() {
      return this.quickGuideItems.map((el) => {
        const title = this.translate(el.title)
        const description = this.translate(el.description)
        el = {
          ...el,
          title,
          description
        }
        return el
      })
    }
  },
  mounted() {
    this.initSwiper()
  },
  beforeDestroy() {
    this.destroySwiper()
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    destroySwiper(deleteInstance = true, cleanStyles = true) {
      if (this.swiper) {
        this.swiper.destroy(deleteInstance, cleanStyles)
      }
    },
    initSwiper() {
      this.swiper = new Swiper(this.$refs.inAppMessagingCarousel, this.swiperOptions)
    }
  }
}
</script>

<style lang="scss">
.quick-guide-carousel {
  position: relative;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  max-width: 1120px;
  z-index: 100000;
  @media (min-width: 1921px) {
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding-right: 0;
  }
  .swiper-pagination {
    position: static;
    @media (max-width: 991px) {
      max-width: 224px;
    }
  }
  .swiper-slide {
    @media (max-width: 767px) {
      height: unset;
    }
  }
  .swiper-pagination-bullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 25px;
    width: 25px;
    height: 25px;
    border: 1px solid #12598d;
    box-sizing: border-box;
    color: #12598d;
    background: transparent;
    opacity: 1;
    font-weight: 400;
    margin: 0 10px !important;
    &.swiper-pagination-bullet-active {
      background: #12598d !important;
      color: #fff;
      font-weight: 700;
    }
    @media (max-width: 991px) {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin: 0 6px !important;
      font-size: 11px;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    user-select: none;
    min-width: 25px;
    width: 25px;
    height: 25px;
    margin-top: 0;
    color: #12598d;
    &:after {
      display: none;
    }
    @media (max-width: 767px) {
      min-width: 18px;
      width: 18px;
      height: 18px;
      .material-icons {
        font-size: 18px;
      }
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 440px;
    position: absolute;
    right: 40px;
    bottom: 30px;
    @media (max-width: 991px) {
      left: 0;
      max-width: 100%;
      right: unset;
      padding: 0 40px;
    }
    @media (max-width: 364px) {
      padding: 0 15px;
    }
  }
}
</style>
