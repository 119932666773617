<template>
  <quick-guide-presentation class="_start">
    <template #top-image>
      <img :src="require('@/assets/images/quick-guide/girlande.svg')" alt="girlande" />
    </template>
    <template #title>{{ $t('quickGuide.welcome') }} {{ activeUserInfo && activeUserInfo.displayName }} !</template>
    <template #description>
      <div>
        <p>{{ $t('quickGuide.quikGuideStartText1') }}</p>
        <p>{{ $t('quickGuide.quikGuideStartText2') }}</p>
        <p class="mt-6">{{ $t('quickGuide.quikGuideStartText3') }}</p>
        <p class="mt-6" style="color: #12598d; font-weight: 500" v-if="isMobile">{{ $t('quickGuide.quikGuideStartText4') }}</p>
      </div>
    </template>
    <template #bottom-center-image>
      <img :src="require('@/assets/images/quick-guide/ballons.svg')" alt="ballons" />
    </template>
    <template #actions>
      <auth-button btnStyle="grey" @action-click="onQuickGuideActionClick">
        {{ $t('quickGuide.quickGuideButtonTitle') }}
        <template #right-icon>
          <svg class="ml-2" width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6.47363L20.4319 6.47363" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 1.37096L21 6.47406L15 11.5771" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </template>
      </auth-button>
      <auth-button style="margin-top: 10px" @action-click="onActionClick" v-if="!isMobile">
        {{ $t('quickGuide.createYourFirstDialog') }}
        <template #right-icon>
          <svg class="ml-2" width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6.47363L20.4319 6.47363" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 1.37096L21 6.47406L15 11.5771" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </template>
      </auth-button>
    </template>
  </quick-guide-presentation>
</template>

<script>
import { mapGetters } from 'vuex'

import AuthButton from '@/components/buttons/auth/AuthButton.vue'
import QuickGuidePresentation from '@/components/quick-guide/QuickGuidePresentation.vue'

export default {
  name: 'QuickGuideStart',
  components: {
    QuickGuidePresentation,
    AuthButton
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    isMobile() {
      return this.windowWidth <= 991
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    onQuickGuideActionClick() {
      this.$serverBus.$emit('quick-guide-start')
    },
    onActionClick() {
      this.$serverBus.$emit('create-first-dialog')
    }
  }
}
</script>
