var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("quick-guide-presentation", {
    staticClass: "_start",
    scopedSlots: _vm._u([
      {
        key: "top-image",
        fn: function () {
          return [
            _c("img", {
              attrs: {
                src: require("@/assets/images/quick-guide/girlande.svg"),
                alt: "girlande",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              _vm._s(_vm.$t("quickGuide.welcome")) +
                " " +
                _vm._s(_vm.activeUserInfo && _vm.activeUserInfo.displayName) +
                " !"
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "description",
        fn: function () {
          return [
            _c("div", [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("quickGuide.quikGuideStartText1"))),
              ]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("quickGuide.quikGuideStartText2"))),
              ]),
              _c("p", { staticClass: "mt-6" }, [
                _vm._v(_vm._s(_vm.$t("quickGuide.quikGuideStartText3"))),
              ]),
              _vm.isMobile
                ? _c(
                    "p",
                    {
                      staticClass: "mt-6",
                      staticStyle: { color: "#12598d", "font-weight": "500" },
                    },
                    [_vm._v(_vm._s(_vm.$t("quickGuide.quikGuideStartText4")))]
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom-center-image",
        fn: function () {
          return [
            _c("img", {
              attrs: {
                src: require("@/assets/images/quick-guide/ballons.svg"),
                alt: "ballons",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "auth-button",
              {
                attrs: { btnStyle: "grey" },
                on: { "action-click": _vm.onQuickGuideActionClick },
                scopedSlots: _vm._u([
                  {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c(
                          "svg",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              width: "22",
                              height: "13",
                              viewBox: "0 0 22 13",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1 6.47363L20.4319 6.47363",
                                stroke: "white",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M15 1.37096L21 6.47406L15 11.5771",
                                stroke: "white",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("quickGuide.quickGuideButtonTitle")) +
                    "\n      "
                ),
              ]
            ),
            !_vm.isMobile
              ? _c(
                  "auth-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    on: { "action-click": _vm.onActionClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _c(
                                "svg",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    width: "22",
                                    height: "13",
                                    viewBox: "0 0 22 13",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M1 6.47363L20.4319 6.47363",
                                      stroke: "white",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M15 1.37096L21 6.47406L15 11.5771",
                                      stroke: "white",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3601731817
                    ),
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("quickGuide.createYourFirstDialog")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }