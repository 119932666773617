var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("quick-guide-presentation", {
    staticClass: "_end",
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(_vm._s(_vm.$t("quickGuide.endTitle")))]
        },
        proxy: true,
      },
      {
        key: "description",
        fn: function () {
          return [
            _c("p", { staticClass: "mb-6" }, [
              _vm._v(_vm._s(_vm.$t("quickGuide.endSubtitle"))),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$t("quickGuide.endSubtitle2")))]),
            _vm.isMobile
              ? _c(
                  "p",
                  {
                    staticClass: "mt-6",
                    staticStyle: { color: "#12598d", "font-weight": "500" },
                  },
                  [_vm._v(_vm._s(_vm.$t("quickGuide.endSubtitle3")))]
                )
              : _vm._e(),
            !_vm.isMobile
              ? _c(
                  "auth-button",
                  {
                    staticClass: "mt-6",
                    on: { "action-click": _vm.onActionClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _c(
                                "svg",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    width: "22",
                                    height: "13",
                                    viewBox: "0 0 22 13",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M1 6.47363L20.4319 6.47363",
                                      stroke: "white",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M15 1.37096L21 6.47406L15 11.5771",
                                      stroke: "white",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3601731817
                    ),
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("quickGuide.createYourFirstDialog")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "right-img",
        fn: function () {
          return [
            _c("img", {
              attrs: {
                src: require("@/assets/images/quick-guide/rocket.svg"),
                alt: "rocket",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }