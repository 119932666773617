var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "auth-button",
      class: {
        _orange: _vm.btnStyle === "orange",
        _grey: _vm.btnStyle === "grey",
        _white: _vm.btnStyle === "white",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("action-click")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "auth-button__left-icon",
          class: { "auth-button__left-icon_absolute": _vm.isIconAbsolute },
        },
        [_vm._t("left-icon")],
        2
      ),
      _c("div", { staticClass: "auth-button__text" }, [_vm._t("default")], 2),
      _vm._t("right-icon"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }