<template>
  <div class="quick-guide__item">
    <quick-guide-close />
    <div class="quick-guide__item-holder">
      <div class="quick-guide__item-left">
        <div class="quick-guide__item-count">0{{ index + 1 }}</div>
        <div class="quick-guide__item-title">{{ item.title }}</div>
        <div class="quick-guide__item-description" v-html="item.description"></div>
      </div>
      <div class="quick-guide__item-right">
        <img :src="item.image" alt="page-image" />
      </div>
    </div>
  </div>
</template>

<script>
import QuickGuideClose from '@/components/quick-guide/QuickGuideClose'

export default {
  name: 'QuickGuideCarouselSlide',
  components: {
    QuickGuideClose
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-guide {
  &__item {
    padding: 74px 30px 30px 60px;
    width: 100%;
    min-height: 478px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: url('../../assets/images/quick-guide/background.png'), #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 991px) {
      padding: 30px;
    }
    @media (max-width: 364px) {
      padding: 30px 15px;
    }
    &-holder {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (min-width: 1921px) {
        justify-content: center;
      }
      @media (max-width: 991px) {
        flex-direction: column;
        padding-bottom: 30px;
      }
    }
    &-left {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      @media (max-width: 991px) {
        margin-right: 0;
      }
    }
    &-right {
      padding-right: 50px;
      max-width: 100%;
      img {
        max-width: 100%;
      }
      @media (max-width: 991px) {
        padding-right: 0;
      }
    }
    &-count {
      line-height: 1;
      font-size: 200px;
      font-weight: bold;

      color: #f0a941;
      @media (max-width: 991px) {
        font-size: 130px;
      }
    }
    &-title {
      font-size: 24px;
      color: #f0a941;
      line-height: 1;
      letter-spacing: 0.15em;

      font-weight: 600;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 25px;
      }
    }
    &-description {
      font-size: 20px;
      line-height: 26px;
      color: #12598d;
      max-width: 400px;
      @media (max-width: 991px) {
        font-size: 18px;
        margin-bottom: 60px;
        max-width: 100%;
      }
    }
  }
}
</style>
