var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quick-guide__close",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$serverBus.$emit("quick-guide-close")
        },
      },
    },
    [_c("close-icon", { attrs: { width: 20, height: 20 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }