var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-guide__item" },
    [
      _c("quick-guide-close"),
      _c("div", { staticClass: "quick-guide__item-holder" }, [
        _c("div", { staticClass: "quick-guide__item-left" }, [
          _c("div", { staticClass: "quick-guide__item-count" }, [
            _vm._v("0" + _vm._s(_vm.index + 1)),
          ]),
          _c("div", { staticClass: "quick-guide__item-title" }, [
            _vm._v(_vm._s(_vm.item.title)),
          ]),
          _c("div", {
            staticClass: "quick-guide__item-description",
            domProps: { innerHTML: _vm._s(_vm.item.description) },
          }),
        ]),
        _c("div", { staticClass: "quick-guide__item-right" }, [
          _c("img", { attrs: { src: _vm.item.image, alt: "page-image" } }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }